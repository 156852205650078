@font-face {
  font-family: "Gotham Medium";
  src: url("./Gotham-Medium.otf");     
}

@font-face {
  font-family: "Gotham Bold";
  src: url("./Gotham-Bold.otf");     
}

@font-face {
  font-family: "Gotham Book";
  src: url("./Gotham-Book.otf");     
}


h1,h2,h4{
  font-family: "Gotham Bold", sans-serif;
}

p{
  font-family: "Gotham Book", sans-serif;
}


.App {
  text-align: center;
}

.articleCategory{
  font-family: "Gotham Bold", sans-serif
}

.editInput{
  width: 100%;
}

.loggedInGreeting {
  font-family: "Gotham Medium", sans-serif;
}

.navRow a{
  font-family: "Gotham Bold", sans-serif;
  color: #d6d6db;
  text-decoration: none;
}

.navRow a:active{
  color: white;
  text-decoration: none;
}

.navRow a:hover{
  color: white;
  text-decoration: none;
}

.navRow{
  background-image: linear-gradient(to right, #f47920, #d71925);
  width: 100%;
  font-size: large;
}
.topLogo{
  height:100px;
  width:100px;
}

@media (max-width: 500px) {
  .topLogo{ height: 50px;
  width:50px;}
  .loggedInGreeting{font-size: 10px}

}